import React, { createContext, useContext, useEffect, useState } from 'react';

type ImageFormatSupport = {
  avif: boolean;
  webp: boolean;
};

const ImageFormatContext = createContext<ImageFormatSupport | null>(null);

type SupportedMimeType = 'image/avif' | 'image/webp';

const testImages: Record<SupportedMimeType, string> = {
  'image/avif':
    'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=',
  'image/webp':
    'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA=='
};

function checkImageFormatSupport(
  mimeType: SupportedMimeType
): Promise<boolean> {
  return new Promise(resolve => {
    const img = new Image();

    const timeout = setTimeout(() => {
      resolve(false);
    }, 1000);

    img.onload = async () => {
      clearTimeout(timeout);
      try {
        await img.decode();
        resolve(true);
      } catch {
        resolve(false);
      }
    };

    img.onerror = () => {
      clearTimeout(timeout);
      resolve(false);
    };

    img.src = testImages[mimeType];
  });
}

export function ImageFormatProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [formatSupport, setFormatSupport] = useState<ImageFormatSupport>({
    avif: false,
    webp: false
  });

  useEffect(() => {
    async function detectFormats() {
      const [avifSupport, webpSupport] = await Promise.all([
        checkImageFormatSupport('image/avif'),
        checkImageFormatSupport('image/webp')
      ]);

      setFormatSupport({
        avif: avifSupport,
        webp: webpSupport
      });
    }

    detectFormats();
  }, []);

  return (
    <ImageFormatContext.Provider value={formatSupport}>
      {children}
    </ImageFormatContext.Provider>
  );
}

export function useImageFormat() {
  const context = useContext(ImageFormatContext);
  if (context === null) {
    throw new Error(
      'useImageFormat must be used within an ImageFormatProvider'
    );
  }
  return context;
}

export function useOptimalImageUrl(asset: {
  original: string;
  avif?: string;
  webp?: string;
}) {
  const formatSupport = useImageFormat();

  if (formatSupport.avif && asset.avif) {
    return asset.avif;
  }

  if (formatSupport.webp && asset.webp) {
    return asset.webp;
  }

  return asset.original;
}
